import { capitalize, startCase } from "lodash-es";

import { MapFormats } from "./StringHelpers";
import { IInteraction, IMessageDTO, InteractionTypes } from "@/services/DTOs/InteractionDTO";
import { INumberSelectOption, IStringSelectOption } from "@/services/DTOs/Misc";
import { IOperator } from "@/services/DTOs/Person";
import { EmmaFunction, MessageFunctionOutcome } from "@/services/DTOs/Proxima";

export const sortOperatorsAlphabetically = (a: IOperator, b: IOperator): number => {
  const firstText = a.bio.name.toLowerCase();
  const secondText = b.bio.name.toLowerCase();

  return (firstText < secondText) ? -1 : (firstText > secondText) ? 1 : 0;
};

export const sortStringsAlphabetically = (a: string, b: string): number => {
  const firstText = a.toLowerCase();
  const secondText = b.toLowerCase();

  return (firstText < secondText) ? -1 : (firstText > secondText) ? 1 : 0;
};

export const sortStringOptionsAlphabetically = (a: IStringSelectOption, b: IStringSelectOption) => {
  return a.text.localeCompare(b.text);
};

export const sortNumberOptionsNumerically = (a: INumberSelectOption, b: INumberSelectOption) => {
  return a.value - b.value;
};

export function createSelectOptionsFromEnum<T extends object>(enumObject: T): IStringSelectOption[] {
  return Object.keys(enumObject).map((key) => {
    const keyOfEnumObject = key as keyof typeof enumObject;

    return {
      text: MapFormats(key, startCase, capitalize),
      value: enumObject[keyOfEnumObject] as unknown as string
    };
  }).sort(sortStringOptionsAlphabetically);
};

export function parseMessagesFromInteractions(interactions: IInteraction[]): IMessageDTO[] {
  return interactions.filter(
    interaction =>
      interaction.interactionType === InteractionTypes.MESSAGE ||
      interaction.interactionType === InteractionTypes.EXTERNAL_MESSAGE
  ) as IMessageDTO[];
};

export function parseFunctionsToShow(functions: EmmaFunction[]): EmmaFunction[] {
  const EXCLUDED_FUNCTIONS_TO_SHOW = ["route-message", "detect-language", "refer-candidate"];
  const PRIORITIZED_FUNCTIONS_TO_SHOW = ["change-agent"];

  return functions.filter(f =>
    (f.outcome === MessageFunctionOutcome.SUCCESS && !EXCLUDED_FUNCTIONS_TO_SHOW.includes(f.type)) ||
    PRIORITIZED_FUNCTIONS_TO_SHOW.includes(f.type)
  );
}
