import { IMenuOption } from "@/services/DTOs/Misc";
import { IPagePermissionMeta, PermissionTinyDTO } from "@/services/DTOs/Permission";

export const hasPermissions = (config: IPagePermissionMeta, permissions: PermissionTinyDTO[]): boolean => {
  if (config.directive === "exact") {
    return config.permissions.every(p => permissions.some(up => up.name === p.name && up.category === p.category));
  } else if (config.directive === "inclusive") {
    return config.permissions.some(p => permissions.some(up => up.name === p.name && up.category === p.category));
  }

  return false;
};

export const hasNotPermissions = (config: IPagePermissionMeta, permissions: PermissionTinyDTO[]): boolean => {
  return !hasPermissions(config, permissions);
};

export const validatePermissions = (
  config: IPagePermissionMeta,
  permissions: PermissionTinyDTO[],
  checker: (config: IPagePermissionMeta, permissions: PermissionTinyDTO[]) => boolean
): boolean => {
  return config.permissions.length > 0
    ? checker(config, permissions)
    : true;
};

export class PagePermissionManager {
  private _routes: Map<string, Omit<IMenuOption, "to">> = new Map<string, Omit<IMenuOption, "to">>();
  private _transformedRoutes: Map<string, Omit<IMenuOption, "to">> = new Map<string, Omit<IMenuOption, "to">>();

  constructor(routes: IMenuOption[]) {
    this._routes = this.buildRoutes(routes);
    this._transformedRoutes = this._routes;
  }

  get listAll(): IMenuOption[] {
    return Array.from(this._transformedRoutes.entries()).map(([key, value]) => ({
      ...value,
      to: key
    }));
  }

  findByPath(path: string): IMenuOption | undefined {
    const option = this._transformedRoutes.get(path);

    if (option) {
      return { ...option, to: path };
    }
  }

  findPermissionMeta(path: string): IPagePermissionMeta | undefined {
    const option = this.findByPath(path);

    if (option && option.permissions && option.permissions?.length > 0) {
      return {
        directive: "exact",
        permissions: option.permissions
      };
    }
  }

  listChildren(parent: string): IMenuOption[] {
    return Array.from(this._transformedRoutes.entries())
      .filter(([key]) => key.startsWith(parent))
      .map(([key, value]) => ({
        ...value,
        to: key
      }));
  }

  listByLevel(level: number): IMenuOption[] {
    return Array.from(this._transformedRoutes.entries())
      .filter(([key]) => key.split("/").length === level)
      .map(([key, value]) => ({
        ...value,
        to: key
      }));
  }

  applyTransformations(transformations: Map<string, Partial<Omit<IMenuOption, "to">>>): PagePermissionManager {
    this._transformedRoutes = Array.from(this._transformedRoutes.entries()).reduce((acc, [key, value]) => {
      const transformedValue = transformations.get(key) || {};

      acc.set(key, {
        ...value,
        ...transformedValue
      });
      return acc;
    }, this._transformedRoutes);

    return this;
  }

  concat(pagePermission: PagePermissionManager): PagePermissionManager {
    this._transformedRoutes = Array.from(this._transformedRoutes.entries()).reduce((acc, [key, value]) => {
      const pagePermissionValue = pagePermission.findByPath(key) || {};

      acc.set(key, {
        ...value,
        ...pagePermissionValue
      });
      return acc;
    }, this._transformedRoutes);

    return this;
  }

  concatAll(pagePermissions: PagePermissionManager[]): PagePermissionManager {
    pagePermissions.forEach((pagePermission) => {
      this.concat(pagePermission);
    });

    return this;
  }

  copy(): PagePermissionManager {
    return new PagePermissionManager(this.listAll);
  }

  private buildRoutes(routes: IMenuOption[]): Map<string, Omit<IMenuOption, "to">> {
    return routes.reduce((acc, route) => {
      acc.set(route.to, {
        text: route.text,
        icon: route.icon,
        permissions: route.permissions,
        show: route.show
      });
      return acc;
    }, new Map<string, Omit<IMenuOption, "to">>());
  }
};
