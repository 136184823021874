import { sign, verify } from "jsonwebtoken";

import { IPagePermissionOverride } from "@/services/DTOs/PagePermissionOverride";

class Helper {
  private publicKey = "0B590A4379B04AD00AFD88BBF7F2169E";
  private sessionStorageKey = "pageAccessToken";

  public generatePageAccessToken<T extends IPagePermissionOverride>(data: T): void {
    const initializationVector = this.generateRandomInitializationVector();
    const token = initializationVector.length.toString() + initializationVector + btoa(JSON.stringify(data));
    const jwtToken = sign({ token }, this.publicKey, { expiresIn: "1h" });

    localStorage.setItem(this.sessionStorageKey, jwtToken);
  }

  public decodePageAccessToken<T extends IPagePermissionOverride>(): T | null {
    try {
      const token = (verify(localStorage.getItem(this.sessionStorageKey) ?? "", this.publicKey) as {token: string}).token;

      const ivLength = parseInt(token.substring(0, 2), 10);
      const encodedData = token.substring(ivLength + 2);
      const decodedData = atob(encodedData);

      return JSON.parse(decodedData) as T;
    } catch (error) {
      return null;
    }
  }

  public removePageAccessToken(): void {
    localStorage.removeItem(this.sessionStorageKey);
  }

  private generateRandomInitializationVector(): string {
    const size = Math.floor(Math.random() * 8) + 10;
    const randomBytes = new Uint8Array(size);

    crypto.getRandomValues(randomBytes);
    const hexString = Array.from(randomBytes)
      .map(byte => byte.toString(16).padStart(2, "0"))
      .join("");

    return hexString;
  }
}

export const PagePermissionOverrideHelper = new Helper();
