import { capitalize, isBoolean, isEmpty, isNumber, isObject, omitBy, startCase } from "lodash-es";

import { BooleanToPolarString, MapFormats } from "./StringHelpers";
import { TreeItemDTO } from "@/services/DTOs/DataPointDTO";

export const CreateTreeItemFromObjectOrArray = (data: object | Array<any>, parentKey?: string): TreeItemDTO[] => {
  const treeData = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const dataKey = key as keyof typeof data;
      const element = data[dataKey];
      const isObjectOrArray = typeof element === "object" && element !== null;
      const isObject = isObjectOrArray && !Array.isArray(element);
      const isArray = isObjectOrArray && Array.isArray(element);
      const treeItem = {
        id: parentKey ? `${parentKey}.${key}` : key,
        name: MapFormats(key, startCase, capitalize) + ":"
      } as TreeItemDTO;

      if (isObject || isArray) {
        treeItem.children = CreateTreeItemFromObjectOrArray(element, treeItem.id);
      } else {
        treeItem.value = typeof element === "boolean" ? BooleanToPolarString(element) : element;
      }
      treeData.push(treeItem);
    }
  }
  return treeData;
};

export const MapToStringKeyedObject = <T> (data: Map<string, T>): { [key: string]: T } => {
  const obj = {} as { [key: string]: T };

  for (const [key, value] of data.entries()) {
    obj[key] = value;
  }
  return obj;
};

export const removeEmptyFieldsFromObject = (obj: object): object => {
  return omitBy(obj, (f) => {
    if (isNumber(f) || (isObject(f) && !isEmpty(f)) || isBoolean(f)) {
      return false;
    } else {
      return !(f as any)?.length;
    }
  });
};

export const countNonEmptyFields = (obj: object): number => {
  return Object.keys(removeEmptyFieldsFromObject(obj)).length;
};
