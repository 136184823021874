import Hashids from "hashids";

const urlPatternRegex = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;
const pseudoUrlPatternRegex = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
const urlStringRegex = new RegExp(
  "^([a-zA-Z]+:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
  "i"
);
const urlStringDataPointRegex = new RegExp(
  "^([a-zA-Z]+:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%@.!#$%^&_=~+=-]*)?" +
    "(\\#[-a-z\\d%!#$%^&_=]*)?$",
  "i"
);
const phoneRegex = /^\+\d{4,15}$/;
const ggidRegex = /^(\d+|[0-9a-fA-F]{32})$/;
const emailRegex = /^[A-Za-z0-9_-]+(?:\.[A-Za-z0-9_-]+)*@[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]{2,})+$/;
const emailTestRegex = /^[A-Za-z0-9_-]+[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+(?!.*\.$)$/;
const linkedInProfileURLRegex = /^https:\/\/www\.linkedin\.com\/in\/[^/]+\/?(\?.*)?$/;

export const UnderscoreToSpace = (value: string): string => {
  return (value || "").replaceAll("_", " ");
};

export const DashToSpace = (value: string): string => {
  return (value || "").replaceAll("-", " ");
};

export const MapFormats = (value: string, ...formatters: ((value: string) => string)[]): string => {
  return formatters.reduce((acc, formatter) => formatter(acc), value);
};

export const BooleanToPolarString = (value: boolean): string => {
  return value ? "Yes" : "No";
};

export const HashToNumber = (hash: string): number | null => {
  const hashids = new Hashids(
    "jS5qAlR0kHbAeqeuVowPBnyotKuTvvlGzIzkEtz79XQGB7BJHb",
    8,
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
  );

  return Number(hashids.decode(hash)[0]?.valueOf());
};

export const Linkify = (value: string, className?: string): string => {
  const valueWithStrippedHTMLTags = ("" + value)?.replaceAll("<", "[").replaceAll(">", "]");
  const classNameString = className ? `class="${className}"` : `class="text-decoration-none"`;

  return (valueWithStrippedHTMLTags || "")
    .replace(urlPatternRegex, `<a href="$&" ${classNameString} target="_blank">$&</a>`)
    .replace(pseudoUrlPatternRegex, `$1<a href="http://$2" ${classNameString}  target="_blank">$2</a>`);
};

export const hasSpaces = (value: string): boolean => {
  return value.includes(" ");
};

export const isValidUrl = (string: string): boolean => {
  if (string.length <= 2048) {
    return urlStringRegex.test(string);
  }
  return false;
};

export const isValidDataPointUrl = (string: string): boolean => {
  if (string.length <= 2048) {
    return urlStringDataPointRegex.test(string);
  }
  return false;
};

export const isValidEmail = (string: string): boolean => {
  if (string.length <= 254) {
    return emailRegex.test(string);
  }
  return false;
};

export const isValidTestEmail = (string: string): boolean => {
  return emailTestRegex.test(string);
};

export const isValidPhoneNumber = (string: string): boolean => {
  return phoneRegex.test(string);
};

export const isValidGgId = (string: string): boolean => {
  return ggidRegex.test(string);
};

export const isValidLinkedInProfileURL = (value: string): boolean => {
  return linkedInProfileURLRegex.test(value);
};
